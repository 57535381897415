import React from "react";
import ArrowIcon from "../../assets/images/login.svg";
import CarouselSlider from "./carousel";

interface CardItemsProps {
  hero: string;
  position?: string;
  description: string;
  backgroundColor?: string;
  color?: string;
  image?: string;
}

interface CardProps {
  title: string;
  description: string;
}

const CardItems: CardItemsProps[] = [
  {
    hero: "Single Sign-On",
    image: ArrowIcon,
    description:
      "Integrate with SSO IdPs (SAML, LDAP, JWT, Google) and map to Metabase groups.",
    backgroundColor: "#1B3F58",
    color: "#fff",
  },
  {
    hero: "Permissions",
    description:
      "Control access to data, content and features with group permissions.",
    backgroundColor: "#1B3F58",
    color: "#fff",
    image: ArrowIcon,
  },
  {
    hero: "Sandboxing",
    image: ArrowIcon,
    description:
      "Segment access and unlock multi-tenant analytics via row-level data sandboxes.",
    backgroundColor: "#1B3F58",
    color: "#fff",
  },
  {
    hero: "Caching",
    description:
      "Go faster with result and model caching. No schedulers or pipelines needed.",
    backgroundColor: "#1B3F58",
    color: "#fff",
    image: ArrowIcon,
  },
  {
    hero: "Audit",
    description:
      "Inspect user behavior across content, data access and data downloads.",
    backgroundColor: "#1B3F58",
    color: "#fff",
    image: ArrowIcon,
  },
  {
    hero: "Verification",
    description:
      "Discern between official and broadly-created content with official and verified badges.",
    backgroundColor: "#1B3F58",
    color: "#fff",
    image: ArrowIcon,
  },
  {
    hero: "SQL Snippets",
    description:
      "Manage and share complex SQL logic across your team with shared snippets.",
    backgroundColor: "#1B3F58",
    color: "#fff",
    image: ArrowIcon,
  },
  {
    hero: "Security and Privacy",
    description:
      "Enterprise-scaled compliance (SOC 2 Type II, GDPR) built right in from day one.",
    backgroundColor: "#1B3F58",
    color: "#fff",
    image: ArrowIcon,
  },
];

const Scale = () => {
  const Card: React.FC<CardProps> = ({ title, description }) => {
    return (
      <div className="card-container flex mt-6 md:mt-0 w-full md:mb-6 bg-white shadow-2xl rounded-lg">
        <div className="bar w-2 h-10 rounded-md bg-[#284B63] mb-4"></div>
        <section>
          <h2 className="text-xl font-semibold mb-2 px-4 py-4">{title}</h2>
          <p className="text-gray-600 px-4 h-36 md:h-20">{description}</p>
        </section>
      </div>
    );
  };
  return (
    <>
      <div className="app-container px-10 pt-10 bg-[#F3F3F3] mt-12 md:mt-0">
        <h1 className="md:text-3xl text-xl font-bold mb-8 text-center my-8 pb-6">
          Make self-service analytics finally happen
        </h1>
        <div className="md:flex gap-6 ">
          <div>
            <Card
              title="Visual Query Builder"
              description="Free your data from the confines of SQL, letting everyone query in a UI humans actually love. Need joins, drill-downs and calculated columns? We’ve got you."
            />
            <Card
              title="Dashboards"
              description="Easily create and share interactive dashboards, from TB-scale analytical workloads to day-to-day operational workflows, with 15+ visualization types."
            />
            <Card
              title="Models"
              description="Craft metadata-rich, semantic models which let people query on their own, keeping things consistent and avoiding repetition. With a single tab open and no CLI needed."
            />
          </div>
          <div className=" bg-[#ECF5FB] md:mt-0 mt-6 w-full rounded-lg h-[300px] md:h-[490px] shadow-xl"></div>{" "}
        </div>

       
      </div>
       <div className=" w-full bg-[#F3F3F3] h-auto pt-12 md:pt-8 pb-16">
          <CarouselSlider />
        </div>

      <div className="w-auto bg-[#284B63] pb-4 md:pb-20 ">
        <h1 className="text-center font-semibold md:text-[50px] text-[22px] text-white pt-10">
          Sneakily scalable
        </h1>
        <p className="text-center md:text-[33px] text-[20px] text-white">
          From pre-seed to post-IPO.
        </p>
        <div className="md:grid md:grid-cols-3 justify-center text-black gap-y-[3%] gap-x-[4%] p-10">
          {CardItems.map(
            ({ hero, description, backgroundColor, color, image }) => (
              <section
                key={hero}
                className="flex flex-col px-7 rounded-[31px] mt-6 shadow-2xl  pt-8 gap-y-4 "
                style={{ backgroundColor: backgroundColor }}
              >
                <img src={image} alt="arrow" className="w-[46px]" />
                <div
                  className="text-base md:text-lg font-semibold"
                  style={{ color: color }}
                >
                  {hero}
                </div>
                <div
                  className="text-sm lg:text-[14px] font-normal h-20"
                  style={{ color: color }}
                >
                  {description}
                </div>
              </section>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Scale;
