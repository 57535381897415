import React from "react";
import Icon from "../../assets/icon";

const NewsLetter = () => {
  return (
    <>
      <div className="bg-[#4F9EE2] flex flex-col justify-center items-center h-[614px rounded-md text-white m-8 py-32">
        <h1 className="md:text-[50px] text-[27px] font-bold">Ready to get started?</h1>
        <p className="md:text-[32px] text-[18px]">Get up and running right now.</p>
        <button className="bg-[#284B63] text-[18px] text-white font-medium px-4 py-2 rounded-lg mt-4">
          <a href="https://bounty-ai.onrender.com/signup" target="_blank" rel="noreferrer">
            Get Started
          </a>
        </button>
      </div>
      <div className="md:flex justify-between m-8">
        <div>
          <h1 className="font-bold text-[16px] md:text-[24px] lg:text-[38px]">Subscribe to our newsletter</h1>
          <p className="lg:text-[22px] md:text-[18px] text-[12px] md:max-w-[310px] lg:max-w-auto">
            Stay in touch with updates and news from Metabase. No spam, ever.
          </p>
        </div>
        <div className="md:w-[40%] lg:w-[28%] w-full h-16 rounded-md border flex justify-between  border-[#284B63] px-5 mt-5 md:mt-0">
          <input placeholder="Type your email address..." type="email" className="outline-none" />
          <Icon name="emailarrow" className="bg-[#284B63] rounded-md mt-4" />
        </div>
      </div>
    </>
  );
};

export default NewsLetter;
