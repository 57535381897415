import React from "react";
import Demo from "../../component/landing-page/demo";
import Hero from "../../component/landing-page/bi-section1";
import CarouselSlider from "../../component/landing-page/carousel";
import Scale from "../../component/landing-page/scalability";
import EmbeddedAnalytics from "../../component/landing-page/embedded-analytics";
import NewsLetter from "../../component/landing-page/newsletter";
import Footer from "../../component/landing-page/footer";

const LandingPage = () => {
  return (
    <>
      <Demo />
      <Hero />
      <CarouselSlider />
      <Scale />
      <EmbeddedAnalytics />
      <NewsLetter />
      <Footer />
    </>
  );
};

export default LandingPage;
