import React from "react";

type IconName =
  | "logoIcon"
  | "arrowIcon"
  | "menuIcon"
  | "demoicon"
  | "gricon"
  | "lessthanIcon"
  | "greaterthanIcon"
  | "arrow"
  | "emailarrow"
  | "footerlogo"
  | "facebook"
  | "x"
  | "ig"
  | "linkedin"
  | "youtube";

interface IconProps {
  name: IconName;
  className?: string;
  onClick?: any;
}

const Icon: React.FC<IconProps> = ({ name, className }) => {
  switch (name) {
    case "logoIcon":
      return (
        <svg
          className={className}
          width="87"
          height="81"
          viewBox="0 0 87 81"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M76.3848 39.444C81.1281 35.1419 83.4998 29.9573 83.4998 23.7247C83.4998 17.4922 81.1281 12.1973 76.2745 8.00546C71.4208 3.81365 65.4089 1.6626 58.0732 1.6626H30.4956C32.0399 4.03428 32.8673 6.84721 32.8121 9.82559C32.7569 17.5473 26.8553 23.7247 19.2439 24.2763V49.9235C19.7954 49.3719 20.2918 48.7652 20.8434 48.1034C22.9393 45.5111 24.0976 42.5878 24.3733 38.8373C24.8146 33.3218 27.793 28.5784 32.3157 26.1516C33.86 25.3242 35.5147 24.7727 37.2245 24.6072V18.2643H57.7974C61.8789 18.2643 64.8022 21.2979 64.8022 25.4897C64.8022 26.0412 64.8022 26.3722 64.6919 26.648C64.2506 30.1779 61.4377 32.6599 57.7974 32.6599H52.1716C52.9438 34.2594 53.4953 36.0244 53.716 37.9548L53.7711 38.396C53.9366 42.2569 52.7232 45.7317 50.4618 48.3791H60.0588C64.5816 48.3791 68.0012 51.9091 68.0012 56.4318C68.0012 60.9545 64.5816 64.4845 60.0588 64.4845H37.2245V54.6117C31.2126 56.3767 27.0759 60.6236 24.3733 67.7938C24.1527 68.4005 24.0424 69.5588 23.9321 70.6067C23.8769 71.048 23.8218 71.4892 23.7666 71.9305C23.3254 75.5156 21.8362 78.6594 19.5748 80.9759H60.2794C67.7254 80.9759 73.958 78.8249 79.0322 74.4125C84.1065 70 86.5885 64.5948 86.5885 58.0313C86.6437 50.3096 82.838 43.5255 76.3848 39.444Z"
            fill="#FFC107"
          />
          <path
            d="M18.1959 0.724609C13.0113 0.724609 9.04016 4.69579 9.04016 9.77008C9.09532 14.7892 13.2871 18.8707 18.3062 18.8155C23.2702 18.7052 27.1863 14.7892 27.2414 9.82523C27.3517 4.64064 23.3805 0.724609 18.1959 0.724609ZM18.1959 13.4655C16.1 13.4655 14.4454 11.8108 14.4454 9.71492C14.4454 7.61902 16.1 5.96436 18.1959 5.96436C20.2918 5.96436 21.9465 7.61902 21.9465 9.71492C21.9465 11.8108 20.2367 13.4655 18.1959 13.4655Z"
            fill="#284B63"
          />
          <path
            d="M43.5122 31.2253C37.39 27.8608 30.5507 31.832 29.944 39.278C29.5579 43.911 28.0687 47.9925 25.1455 51.5776C20.6779 57.038 15.383 60.8989 7.99218 61.6159C2.80758 62.1123 -0.446582 66.635 0.0498154 71.4887C0.491058 75.9563 4.35192 79.5965 8.92981 79.7068C13.7835 79.8723 17.6444 76.232 18.2511 71.0474C18.4717 69.2273 18.5268 67.2969 19.1887 65.5871C22.7738 56.2107 29.0615 49.8126 39.3755 48.2131C44.8911 47.3858 48.421 43.6352 48.2004 38.3955C47.8695 35.5274 46.4906 32.8248 43.5122 31.2253ZM9.09528 74.0258C6.99938 74.0258 5.34472 72.3712 5.34472 70.2753C5.34472 68.1794 6.99938 66.5247 9.09528 66.5247C11.1912 66.5247 12.8458 68.1794 12.8458 70.2753C12.8458 72.316 11.136 74.0258 9.09528 74.0258ZM38.7688 42.6425C36.6729 42.6425 35.0183 40.9878 35.0183 38.8919C35.0183 36.796 36.6729 35.1413 38.7688 35.1413C40.8647 35.1413 42.5194 36.796 42.5194 38.8919C42.5194 40.9326 40.8096 42.6425 38.7688 42.6425Z"
            fill="#284B63"
          />
        </svg>
      );

    case "arrowIcon":
      return (
        <svg
          className={className}
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.9253 14.9947C10.3344 14.9947 9.74339 14.7667 9.29595 14.3193L3.7915 8.81484C3.54667 8.57001 3.54667 8.16478 3.7915 7.91995C4.03633 7.67512 4.44157 7.67512 4.6864 7.91995L10.1908 13.4244C10.5961 13.8296 11.2546 13.8296 11.6598 13.4244L17.1643 7.91995C17.4091 7.67512 17.8143 7.67512 18.0592 7.91995C18.304 8.16478 18.304 8.57001 18.0592 8.81484L12.5547 14.3193C12.1073 14.7667 11.5163 14.9947 10.9253 14.9947Z"
            fill="#284B63"
          />
        </svg>
      );

    case "menuIcon":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 7H21"
            stroke="#040821"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M3 12H21"
            stroke="#040821"
            stroke-width="1.5"
            stroke-linecap="round"
          />
          <path
            d="M3 17H21"
            stroke="#040821"
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      );

    case "demoicon":
      return (
        <svg
          className={className}
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.5698 19.8201C19.3798 19.8201 19.1898 19.7501 19.0398 19.6001C18.7498 19.3101 18.7498 18.8301 19.0398 18.5401C22.6498 14.9301 22.6498 9.06011 19.0398 5.46011C18.7498 5.17011 18.7498 4.69012 19.0398 4.40012C19.3298 4.11012 19.8098 4.11012 20.0998 4.40012C24.2898 8.59012 24.2898 15.4101 20.0998 19.6001C19.9498 19.7501 19.7598 19.8201 19.5698 19.8201Z"
            fill="#284B63"
          />
          <path
            d="M5.42982 19.8201C5.23982 19.8201 5.04982 19.7501 4.89982 19.6001C0.709824 15.4101 0.709824 8.59012 4.89982 4.40012C5.18982 4.11012 5.66982 4.11012 5.95982 4.40012C6.24982 4.69012 6.24982 5.17011 5.95982 5.46011C2.34982 9.07011 2.34982 14.9401 5.95982 18.5401C6.24982 18.8301 6.24982 19.3101 5.95982 19.6001C5.80982 19.7501 5.61982 19.8201 5.42982 19.8201Z"
            fill="#284B63"
          />
          <path
            d="M12.4998 22.71C11.2498 22.7 10.0598 22.4999 8.94978 22.1099C8.55978 21.9699 8.34976 21.54 8.48976 21.15C8.62976 20.76 9.04978 20.55 9.44978 20.69C10.4098 21.02 11.4298 21.2 12.5098 21.2C13.5798 21.2 14.6098 21.02 15.5598 20.69C15.9498 20.56 16.3798 20.76 16.5198 21.15C16.6598 21.54 16.4498 21.9699 16.0598 22.1099C14.9398 22.4999 13.7498 22.71 12.4998 22.71Z"
            fill="#284B63"
          />
          <path
            d="M15.8 3.34009C15.72 3.34009 15.63 3.33005 15.55 3.30005C14.59 2.97005 13.56 2.79004 12.49 2.79004C11.42 2.79004 10.4 2.97005 9.43999 3.30005C9.04999 3.43005 8.61999 3.23009 8.47999 2.84009C8.33999 2.45009 8.54999 2.02007 8.93999 1.88007C10.05 1.49007 11.25 1.29004 12.49 1.29004C13.73 1.29004 14.93 1.49007 16.04 1.88007C16.43 2.02007 16.64 2.45009 16.5 2.84009C16.4 3.15009 16.11 3.34009 15.8 3.34009Z"
            fill="#284B63"
          />
          <path
            d="M11.0602 16.76C10.6202 16.76 10.2002 16.65 9.83023 16.44C8.97023 15.94 8.49023 14.96 8.49023 13.68V10.33C8.49023 9.05003 8.96023 8.07002 9.83023 7.57002C10.6902 7.07002 11.7803 7.15 12.8903 7.79L15.7903 9.46004C16.9003 10.1 17.5103 11 17.5103 12C17.5103 13 16.9003 13.9 15.7903 14.54L12.8903 16.21C12.2603 16.58 11.6302 16.76 11.0602 16.76ZM11.0602 8.74001C10.8802 8.74001 10.7102 8.78 10.5802 8.86C10.2102 9.08 9.99023 9.61002 9.99023 10.32V13.67C9.99023 14.38 10.2002 14.92 10.5802 15.13C10.9502 15.34 11.5203 15.26 12.1403 14.91L15.0403 13.24C15.6603 12.88 16.0103 12.43 16.0103 12C16.0103 11.57 15.6603 11.12 15.0403 10.76L12.1403 9.09004C11.7403 8.86004 11.3702 8.74001 11.0602 8.74001Z"
            fill="#284B63"
          />
        </svg>
      );

    case "gricon":
      return (
        <svg
          width="742"
          height="1242"
          viewBox="0 0 742 1242"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.2" filter="url(#filter0_f_1_508)">
            <circle
              cx="621"
              cy="621"
              r="478"
              fill="url(#paint0_linear_1_508)"
            />
            <circle
              cx="621"
              cy="621"
              r="478"
              fill="url(#paint1_linear_1_508)"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_1_508"
              x="0.477158"
              y="0.477158"
              width="1241.05"
              height="1241.05"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="71.2614"
                result="effect1_foregroundBlur_1_508"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1_508"
              x1="621"
              y1="143"
              x2="621"
              y2="1099"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F9940C" />
              <stop offset="1" stop-color="#F9B70C" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1_508"
              x1="621"
              y1="143"
              x2="621"
              y2="1099"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFC107" />
              <stop offset="1" stop-color="#284B63" />
            </linearGradient>
          </defs>
        </svg>
      );

    case "greaterthanIcon":
      return (
        <svg
          className={className}
          width="55"
          height="55"
          viewBox="0 0 55 55"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_8_135)">
            <circle cx="27.5" cy="23.5" r="23.5" fill="white" />
          </g>
          <path
            d="M31 34L21 24L31 14"
            stroke="#4E4E4E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <filter
              id="filter0_d_8_135"
              x="0"
              y="0"
              width="55"
              height="55"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_8_135"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_8_135"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      );

    case "lessthanIcon":
      return (
        <svg
          className={className}
          width="55"
          height="55"
          viewBox="0 0 55 55"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_8_140)">
            <circle
              cx="23.5"
              cy="23.5"
              r="23.5"
              transform="matrix(-1 0 0 1 51 0)"
              fill="white"
            />
          </g>
          <path
            d="M24 34L34 24L24 14"
            stroke="#4E4E4E"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <defs>
            <filter
              id="filter0_d_8_140"
              x="0"
              y="0"
              width="55"
              height="55"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_8_140"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_8_140"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      );

    case "arrow":
      return (
        <svg
          className={className}
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.90998 20.67C8.71998 20.67 8.52998 20.6 8.37998 20.45C8.08998 20.16 8.08998 19.68 8.37998 19.39L14.9 12.87C15.38 12.39 15.38 11.61 14.9 11.13L8.37998 4.61C8.08998 4.32 8.08998 3.84 8.37998 3.55C8.66998 3.26 9.14998 3.26 9.43998 3.55L15.96 10.07C16.47 10.58 16.76 11.27 16.76 12C16.76 12.73 16.48 13.42 15.96 13.93L9.43998 20.45C9.28998 20.59 9.09998 20.67 8.90998 20.67Z"
            fill="#058FF3"
          />
        </svg>
      );

    case "emailarrow":
      return (
        <svg
          className={className}
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.33 32.445C19.56 32.445 17.055 31.2 15.075 25.245L13.995 22.005L10.755 20.925C4.81501 18.945 3.57001 16.44 3.57001 14.67C3.57001 12.915 4.81501 10.395 10.755 8.39998L23.49 4.15498C26.67 3.08998 29.325 3.40498 30.96 5.02498C32.595 6.64498 32.91 9.31498 31.845 12.495L27.6 25.23C25.605 31.2 23.1 32.445 21.33 32.445ZM11.46 10.545C7.29001 11.94 5.80501 13.59 5.80501 14.67C5.80501 15.75 7.29001 17.4 11.46 18.78L15.24 20.04C15.57 20.145 15.84 20.415 15.945 20.745L17.205 24.525C18.585 28.695 20.25 30.18 21.33 30.18C22.41 30.18 24.06 28.695 25.455 24.525L29.7 11.79C30.465 9.47998 30.33 7.58998 29.355 6.61498C28.38 5.63998 26.49 5.51998 24.195 6.28498L11.46 10.545Z"
            fill="white"
          />
          <path
            d="M15.165 21.6C14.88 21.6 14.595 21.495 14.37 21.27C13.935 20.835 13.935 20.115 14.37 19.68L19.74 14.295C20.175 13.86 20.895 13.86 21.33 14.295C21.765 14.73 21.765 15.45 21.33 15.885L15.96 21.27C15.75 21.495 15.45 21.6 15.165 21.6Z"
            fill="white"
          />
        </svg>
      );

    case "footerlogo":
      return (
        <svg
          className={className}
          width="202"
          height="41"
          viewBox="0 0 202 41"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M77.9256 28.2047C76.6048 27.4893 75.5867 26.4986 74.8437 25.2329C74.1008 23.9671 73.743 22.5362 73.743 20.9127C73.743 19.3167 74.1008 17.8858 74.8437 16.62C75.5867 15.3542 76.6048 14.3911 77.9256 13.6757C79.2464 12.9603 80.7324 12.6025 82.3834 12.6025C84.0344 12.6025 85.5479 12.9603 86.8687 13.6757C88.1895 14.3911 89.2076 15.3818 89.9506 16.62C90.6935 17.8858 91.0513 19.3167 91.0513 20.9127C91.0513 22.5087 90.6935 23.9396 89.9506 25.2329C89.2076 26.4986 88.1895 27.4893 86.8687 28.2047C85.5479 28.9201 84.0619 29.2779 82.3834 29.2779C80.7049 29.2779 79.2464 28.9201 77.9256 28.2047ZM85.823 24.6C86.7311 23.6644 87.1989 22.4261 87.1989 20.9127C87.1989 19.3993 86.7311 18.161 85.823 17.2254C84.915 16.2898 83.7592 15.822 82.3559 15.822C80.9525 15.822 79.7968 16.2898 78.8887 17.2254C77.9807 18.161 77.5404 19.3993 77.5404 20.9127C77.5404 22.4261 77.9807 23.6644 78.8887 24.6C79.7968 25.5356 80.9525 26.0033 82.3559 26.0033C83.7592 26.0033 84.915 25.5356 85.823 24.6Z"
            fill="#284B63"
          />
          <path
            d="M110.423 12.7954V29.058H106.791V26.9942C106.186 27.7372 105.415 28.2875 104.507 28.6727C103.599 29.058 102.608 29.2781 101.563 29.2781C99.3889 29.2781 97.6829 28.6728 96.4446 27.462C95.2063 26.2513 94.5734 24.4626 94.5734 22.0962V12.8229H98.3708V21.6009C98.3708 23.0593 98.701 24.16 99.3614 24.8754C100.022 25.5908 100.957 25.9486 102.168 25.9486C103.516 25.9486 104.617 25.5358 105.415 24.6828C106.213 23.8297 106.626 22.619 106.626 21.0505V12.8229H110.423V12.7954Z"
            fill="#284B63"
          />
          <path
            d="M129.575 14.4187C130.813 15.6294 131.419 17.3905 131.419 19.757V29.0577H127.621V20.2248C127.621 18.7939 127.291 17.7207 126.603 17.0053C125.943 16.2898 124.98 15.9321 123.741 15.9321C122.338 15.9321 121.237 16.3449 120.412 17.1979C119.586 18.0509 119.201 19.2617 119.201 20.8301V29.0577H115.404V12.7952H119.036V14.8865C119.669 14.1435 120.467 13.5656 121.403 13.1804C122.366 12.7952 123.439 12.6025 124.622 12.6025C126.686 12.6025 128.337 13.2079 129.575 14.4187Z"
            fill="#284B63"
          />
          <path
            d="M164.082 12.7954L156.459 30.3513C155.744 32.1124 154.891 33.3506 153.873 34.0936C152.855 34.809 151.616 35.1668 150.185 35.1668C149.387 35.1668 148.562 35.0292 147.791 34.7815C146.993 34.5064 146.361 34.1486 145.838 33.6808L147.351 30.8741C147.709 31.2318 148.149 31.4795 148.644 31.6996C149.14 31.8922 149.635 32.0023 150.158 32.0023C150.818 32.0023 151.369 31.8372 151.809 31.4795C152.249 31.1218 152.634 30.5439 153.02 29.7459L153.295 29.113L146.195 12.7954H150.185L155.304 24.8204L160.449 12.7954H164.082Z"
            fill="#284B63"
          />
          <path
            d="M197.845 9.46602C197.377 9.02575 197.157 8.47541 197.157 7.84251C197.157 7.1821 197.377 6.65928 197.845 6.21901C198.313 5.77874 198.891 5.55859 199.578 5.55859C200.266 5.55859 200.844 5.77874 201.312 6.19149C201.78 6.60425 202 7.12707 202 7.75996C202 8.42037 201.78 8.99823 201.312 9.4385C200.844 9.90629 200.266 10.1264 199.551 10.1264C198.891 10.1264 198.313 9.90629 197.845 9.46602Z"
            fill="#FFC107"
          />
          <path
            d="M201.45 12.7954H197.652V29.058H201.45V12.7954Z"
            fill="#284B63"
          />
          <path
            d="M38.1084 19.317C40.4749 17.1706 41.6581 14.584 41.6581 11.4746C41.6581 8.36517 40.4749 5.72353 38.0534 3.63223C35.6319 1.54094 32.6325 0.467773 28.9728 0.467773H15.2142C15.9847 1.65101 16.3975 3.05438 16.37 4.5403C16.3424 8.39269 13.3981 11.4746 9.60077 11.7498V24.5452C9.87594 24.27 10.1236 23.9673 10.3988 23.6371C11.4444 22.3438 12.0223 20.8854 12.1599 19.0143C12.38 16.2626 13.8659 13.8961 16.1223 12.6854C16.8928 12.2726 17.7183 11.9974 18.5713 11.9149V8.75041H28.8352C30.8714 8.75041 32.3298 10.2638 32.3298 12.3551C32.3298 12.6303 32.3298 12.7954 32.2748 12.933C32.0547 14.6941 30.6513 15.9324 28.8352 15.9324H26.0284C26.4137 16.7304 26.6889 17.6109 26.7989 18.574L26.8264 18.7941C26.909 20.7203 26.3036 22.4539 25.1754 23.7747H29.9634C32.2198 23.7747 33.9258 25.5358 33.9258 27.7922C33.9258 30.0486 32.2198 31.8097 29.9634 31.8097H18.5713V26.8841C15.572 27.7647 13.5082 29.8835 12.1599 33.4607C12.0498 33.7634 11.9948 34.3413 11.9397 34.8641C11.9122 35.0842 11.8847 35.3044 11.8572 35.5245C11.637 37.3131 10.8941 38.8816 9.76587 40.0373H30.0735C33.7883 40.0373 36.8977 38.9641 39.4292 36.7628C41.9608 34.5614 43.1991 31.8647 43.1991 28.5902C43.2266 24.7378 41.3279 21.3532 38.1084 19.317Z"
            fill="#FFC107"
          />
          <path
            d="M9.07796 0C6.49136 0 4.51013 1.98123 4.51013 4.51279C4.53765 7.01685 6.62894 9.05311 9.13299 9.02559C11.6095 8.97055 13.5632 7.01685 13.5908 4.54031C13.6458 1.95371 11.6646 0 9.07796 0ZM9.07796 6.35644C8.03231 6.35644 7.2068 5.53092 7.2068 4.48528C7.2068 3.43963 8.03231 2.61412 9.07796 2.61412C10.1236 2.61412 10.9491 3.43963 10.9491 4.48528C10.9491 5.53092 10.0961 6.35644 9.07796 6.35644Z"
            fill="#284B63"
          />
          <path
            d="M21.7083 15.2168C18.6539 13.5383 15.2418 15.5195 14.9391 19.2343C14.7465 21.5457 14.0035 23.582 12.5451 25.3706C10.3162 28.0948 7.67459 30.021 3.98731 30.3787C1.4007 30.6263 -0.2228 32.8827 0.024853 35.3042C0.244989 37.5331 2.17118 39.3492 4.4551 39.4043C6.8766 39.4868 8.80279 37.6707 9.10548 35.0841C9.21555 34.176 9.24306 33.2129 9.57327 32.3599C11.3619 27.682 14.4988 24.49 19.6445 23.692C22.3962 23.2793 24.1573 21.4081 24.0472 18.794C23.8821 17.3631 23.1942 16.0148 21.7083 15.2168ZM4.53765 36.57C3.492 36.57 2.66649 35.7445 2.66649 34.6989C2.66649 33.6532 3.492 32.8277 4.53765 32.8277C5.5833 32.8277 6.40881 33.6532 6.40881 34.6989C6.40881 35.717 5.55578 36.57 4.53765 36.57ZM19.3418 20.9128C18.2962 20.9128 17.4707 20.0873 17.4707 19.0417C17.4707 17.996 18.2962 17.1705 19.3418 17.1705C20.3875 17.1705 21.213 17.996 21.213 19.0417C21.213 20.0598 20.36 20.9128 19.3418 20.9128Z"
            fill="#284B63"
          />
          <path
            d="M70.1658 19.8946C69.4779 18.9866 68.5423 18.3537 67.3591 17.9684C68.2121 17.5282 68.8725 16.8953 69.3678 16.0698C69.8631 15.2718 70.1108 14.3362 70.1108 13.263C70.1108 11.5295 69.4228 10.1811 68.0745 9.19051C66.7262 8.1999 64.7725 7.70459 62.2684 7.70459H52.2797V19.7571H56.2422H58.2234H62.6536C65.708 19.7571 67.249 20.7752 67.249 22.839C67.249 24.8752 65.708 25.9209 62.6536 25.9209H58.2234H56.2422H52.2797V29.0303H62.8738C65.598 29.0303 67.6617 28.535 69.0926 27.5169C70.5235 26.4987 71.2115 25.0954 71.2115 23.2517C71.1839 21.9309 70.8537 20.8027 70.1658 19.8946ZM64.9926 15.9872C64.2496 16.4825 63.204 16.7302 61.8006 16.7302H56.1871V10.8691H61.8006C63.1765 10.8691 64.2496 11.1167 64.9926 11.612C65.7356 12.1073 66.0933 12.8503 66.0933 13.8134C66.0933 14.7489 65.7356 15.4919 64.9926 15.9872Z"
            fill="#284B63"
          />
          <path
            d="M185.71 7.73242H181.803L172.227 29.0581H176.299L178.39 24.1326H178.418L179.739 21.0232L183.729 11.6949L187.746 21.0232H187.719L191.186 29.0581H195.313L185.71 7.73242Z"
            fill="#284B63"
          />
          <path
            d="M140.582 12.933V12.7954H140.224L142.178 15.9599H144.93V12.933H140.582Z"
            fill="#FFC107"
          />
          <path
            d="M144.985 25.4532C144.407 25.8935 143.719 26.1137 142.866 26.1137C142.123 26.1137 141.573 25.8935 141.187 25.4808C140.802 25.068 140.609 24.4626 140.609 23.6646V17.1981L136.812 11.0894V12.878V15.9324V17.8035V23.7747C136.812 25.5633 137.307 26.9117 138.271 27.8472C139.234 28.7828 140.637 29.2506 142.398 29.2506C143.114 29.2506 143.774 29.1681 144.434 28.9754C145.067 28.7828 145.618 28.5077 146.058 28.1499L144.985 25.4532Z"
            fill="#284B63"
          />
        </svg>
      );

    case "facebook":
      return (
        <svg
          className={className}
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M30.9375 16.5874C30.9375 8.61455 24.4729 2.1499 16.5 2.1499C8.52715 2.1499 2.0625 8.61455 2.0625 16.5874C2.0625 23.7933 7.34121 29.7661 14.2441 30.8502V20.762H10.5774V16.5874H14.2441V13.4066C14.2441 9.78889 16.4001 7.78891 19.6975 7.78891C21.2773 7.78891 22.9298 8.07121 22.9298 8.07121V11.6245H21.1084C19.316 11.6245 18.7552 12.737 18.7552 13.8804V16.5874H22.759L22.1197 20.762H18.7559V30.8515C25.6588 29.7681 30.9375 23.7952 30.9375 16.5874Z"
            fill="#242424"
          />
        </svg>
      );

    case "x":
      return (
        <svg
          className={className}
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="34" height="34" rx="17" fill="black" />
          <path
            d="M18.6192 15.6991L24.9485 8.5H23.4489L17.9507 14.7496L13.5625 8.5H8.5L15.1373 17.9513L8.5 25.5H9.99965L15.8023 18.8987L20.4375 25.5H25.5L18.6192 15.6991ZM16.5646 18.0343L15.8911 17.0925L10.5405 9.60624H12.8442L17.1636 15.6502L17.8343 16.5919L23.4482 24.4476H21.1444L16.5646 18.0343Z"
            fill="white"
          />
        </svg>
      );

    case "linkedin":
      return (
        <svg
        className={className}
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M28.6281 2.0625H4.52977C3.21299 2.0625 2.0625 3.00996 2.0625 4.31127V28.4631C2.0625 29.7715 3.21299 30.9375 4.52977 30.9375H28.6211C29.9449 30.9375 30.9375 29.7638 30.9375 28.4631V4.31127C30.9452 3.00996 29.9449 2.0625 28.6281 2.0625ZM11.0131 26.1312H6.8765V13.2696H11.0131V26.1312ZM9.08789 11.3141H9.05824C7.73437 11.3141 6.87715 10.3286 6.87715 9.09498C6.87715 7.83879 7.75693 6.8765 9.11045 6.8765C10.464 6.8765 11.2922 7.8317 11.3218 9.09498C11.3212 10.3286 10.464 11.3141 9.08789 11.3141ZM26.1312 26.1312H21.9946V19.0988C21.9946 17.4139 21.3926 16.2628 19.896 16.2628C18.7526 16.2628 18.0759 17.0363 17.7749 17.7897C17.6621 18.0604 17.6318 18.4291 17.6318 18.8055V26.1312H13.4952V13.2696H17.6318V15.0595C18.2338 14.2022 19.1742 12.9686 21.3623 12.9686C24.0778 12.9686 26.1319 14.7585 26.1319 18.6173L26.1312 26.1312Z"
            fill="black"
          />
        </svg>
      );

    case "youtube":
      return (
        <svg
        className={className}
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M32.3203 8.56501C31.9397 7.15151 30.8252 6.03702 29.4117 5.65646C26.8294 4.94971 16.4999 4.94971 16.4999 4.94971C16.4999 4.94971 6.17048 4.94971 3.58812 5.62928C2.2018 6.00983 1.06013 7.15151 0.679569 8.56501C0 11.1474 0 16.5024 0 16.5024C0 16.5024 0 21.8846 0.679569 24.4397C1.06013 25.8532 2.17462 26.9677 3.58812 27.3483C6.19767 28.055 16.4999 28.055 16.4999 28.055C16.4999 28.055 26.8294 28.055 29.4117 27.3755C30.8252 26.9949 31.9397 25.8804 32.3203 24.4669C32.9999 21.8846 32.9999 16.5296 32.9999 16.5296C32.9999 16.5296 33.027 11.1474 32.3203 8.56501Z"
            fill="black"
          />
          <path
            d="M13.2108 21.4497L21.8006 16.5024L13.2108 11.5552V21.4497Z"
            fill="#D9D9D9"
          />
        </svg>
      );

    case "ig":
      return (
        <svg
        className={className}
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5 12.031C14.032 12.031 12.0313 14.0317 12.0313 16.4998C12.0313 18.9678 14.032 20.9685 16.5 20.9685C18.968 20.9685 20.9688 18.9678 20.9688 16.4998C20.9688 14.0317 18.968 12.031 16.5 12.031Z"
            fill="black"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.30812 4.23678C14.0493 3.70688 18.9508 3.70688 23.6919 4.23678C26.3022 4.52852 28.4076 6.58521 28.714 9.20466C29.2809 14.0515 29.2809 18.948 28.714 23.7949C28.4076 26.4143 26.3022 28.471 23.6919 28.7627C18.9508 29.2926 14.0493 29.2926 9.30812 28.7627C6.69783 28.471 4.59243 26.4143 4.28607 23.7949C3.71918 18.948 3.71918 14.0515 4.28607 9.20466C4.59243 6.58521 6.69782 4.52852 9.30812 4.23678ZM23.375 8.24976C22.6156 8.24976 22 8.86537 22 9.62476C22 10.3841 22.6156 10.9998 23.375 10.9998C24.1344 10.9998 24.75 10.3841 24.75 9.62476C24.75 8.86537 24.1344 8.24976 23.375 8.24976ZM9.96876 16.4998C9.96876 12.8926 12.8929 9.96851 16.5 9.96851C20.1071 9.96851 23.0313 12.8926 23.0313 16.4998C23.0313 20.1069 20.1071 23.031 16.5 23.031C12.8929 23.031 9.96876 20.1069 9.96876 16.4998Z"
            fill="black"
          />
        </svg>
      );

    default:
      return null;
  }
};

export default Icon;
