import React from "react";
// import Icon from "../../assets/icon";
interface CardItems {
  hero: string;
  description: string;
  backgroundColor?: string;
  color?: string;
}

const cardItems: CardItems[] = [
  {
    hero: "Why Use Bounty Ai?",
    description:
      "Connect directly to any of 20+ data sources, from your startup’s production DB to massive data warehouses. Query immediately, without extracts.",
    backgroundColor: "#fff",
    color: "#000",
  },
  {
    hero: "Why Use Bounty Ai",
    description:
      "Connect directly to any of 20+ data sources, from your startup’s production DB to massive data warehouses. Query immediately, without extracts.",
    backgroundColor: "#fff",
    color: "#000",
  },
  {
    hero: "Why Use Bounty Ai",
    description:
      "Connect directly to any of 20+ data sources, from your startup’s production DB to massive data warehouses. Query immediately, without extracts.",
    backgroundColor: "#fff",
    color: "000",
  },
];

const Hero = () => {
  return (
    <div className="relative bg-no-repeat pt-10 overflow-hidden">

      <div className="hemisphere absolute  right-0 w-[20vw] h-[59vh] rotate-90 blur-3xl drop-shadow-2xl filter"/>
      <h1 className="md:text-[30px] text-[20px] font-semibold text-center">
        Get up and running in no time
      </h1>

      <div className="flex flex-col md:flex-row justify-center mt-6 text-black sm:gap-y-12 gap-x-[4%] gap-y-10 flex-wrap  pb-[100px]">
        {cardItems.map(({ hero, description, backgroundColor, color }) => (
          <section
            key={hero}
            className="flex flex-col px-7 rounded-md mx-7 md:mx-0 shadow-2xl border border-[#fff] gap-y-2 gap-x-2 pt-4 pb-8 basis-[28%] z-50"
            style={{ backgroundColor: backgroundColor }}
          >
            <div
              className="text-base md:text-lg font-semibold mt-40"
              style={{ color: color }}
            >
              {hero}
            </div>
            <div
              className="text-sm lg:text-[14px] font-normal"
              style={{ color: color }}
            >
              {description}
            </div>
          </section>
        ))}
      </div>
    </div>
  );
};

export default Hero;
