import React, { useEffect, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import COO from "../../assets/images/Frame.svg";
import CEO from "../../assets/images/Ellipse.svg";
import Icon from "../../assets/icon";

interface CardItems {
  hero: string;
  position?: string;
  description: string;
  backgroundColor?: string;
  color?: string;
  image?: string;
}

const slideItems: CardItems[] = [
  {
    hero: "Linda Downing",
    position: "CEO, Lindal",
    image: CEO,
    description:
      "The BountyAI is the best thus far. They really came prepared for the future and running with it.",
    backgroundColor: "#fff",
    color: "#000",
  },
  {
    hero: "John Lubambi",
    description: "The process was easy and I had great time on Bounty AI",
    backgroundColor: "#fff",
    color: "#000",
    position: "COO, LubJoh",
    image: COO,
  },
  {
    hero: "Linda Downing",
    position: "CEO, Lindal",
    image: CEO,
    description:
      "The BountyAI is the best thus far. They really came prepared for the future and running with it.",
    backgroundColor: "#fff",
    color: "#000",
  },
  {
    hero: "John Lubami",
    description: "The process was easy and I had great time on Bounty AI",
    backgroundColor: "#fff",
    color: "#000",
    position: "COO, LubJoh",
    image: COO,
  },
];

const CarouselSlider: React.FC = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { loop: true, slidesToScroll: 1 },
    [Autoplay()]
  );

  const nextSlide = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  const prevSlide = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  useEffect(() => {
    const updateEmblaOptions = () => {
      if (emblaApi) {
        if (window.innerWidth <= 768) {
          emblaApi.reInit({ loop: true, slidesToScroll: 1 });
        } else {
          emblaApi.reInit({ loop: true, slidesToScroll: 2 });
        }
      }
    };

    window.addEventListener("resize", updateEmblaOptions);
    updateEmblaOptions();

    return () => {
      window.removeEventListener("resize", updateEmblaOptions);
    };
  }, [emblaApi]);

  return (
    <div className="relative flex justify-center items-center mt-12 h-[280px] px-12">
      <Icon
        name="greaterthanIcon"
        className="left-arrow absolute top-1/2 left-0 text-3xl text-black cursor-pointer z-10 md:ml-8"
        onClick={prevSlide}
      />
      <Icon
        name="lessthanIcon"
        className="right-arrow absolute top-1/2 right-0 text-3xl text-black cursor-pointer z-10 md:mr-8"
        onClick={nextSlide}
      />
      <div className="w-full md:w-[80%] overflow-hidden" ref={emblaRef}>
        <div className="flex">
          {slideItems.map((slide, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row justify-center md:justify-between items-center px-8 py-4 mx-4 shadow-2xl gap-8"
              style={{
                backgroundColor: slide.backgroundColor,
                color: slide.color,
                borderRadius: "10px",
                // minWidth: "50%",
                minWidth: window.innerWidth <= 768 ? "100%" : "50%",
                width: window.innerWidth <= 768 ? "100%" : "50%",
              }}
            >
              {slide.image && (
                <div className="w-[141px] h-[141px] rounded-full overflow-hidden">
                  <img
                    src={slide.image}
                    alt={slide.hero}
                    className="relative w-full h-full mb-4"
                  />
                </div>
              )}
              <div className="text-center md:text-left">
                <h2 className="text-lg font-semibold">{slide.hero}</h2>
                {slide.position && (
                  <h3 className="text-sm">{slide.position}</h3>
                )}
                <p className="text-[15px] mt-2 max-w-[278px]">
                  {slide.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselSlider;
