import React from "react";
import "./App.css";
import Navbar from "./component/navbar";
import LandingPage from "./pages/landing-page";

function App() {
  return (
    <div className="bg-[url(assets/images/Vector.svg)] h-[670px]">
      <Navbar />
      <LandingPage />
    </div>
  );
}

export default App;
