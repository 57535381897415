import React from "react";
import Icon from "../../assets/icon";
import ImgIcon from "../../assets/images/Ellipse 22.svg";
import X from "../../assets/images/Icon.svg";

interface CardProps {
  title: string;
  description: string;
  isPro?: boolean;
  circle?: any;
}

interface CardItems {
  hero: string;
  position?: string;
  description: string;
  backgroundColor?: string;
  color?: string;
  image?: string;
  icon?: string;
}

const Cards: CardItems[] = [
  {
    hero: "Cole Lamar",
    position: "CEO, Lindal",
    image: ImgIcon,
    icon: X,
    description:
      "@bountyai is really really awesome. I love the speed of implementation for new dashboards and reports. Totally awesome. Can’t wait for new features. Might as well learn some clojure and contrib myself!",
    backgroundColor: "#fff",
    color: "#000",
  },
  {
    hero: "Cole Lamar",
    description:
      "@bountyai is really really awesome. I love the speed of implementation for new dashboards and reports. Totally awesome. Can’t wait for new features. Might as well learn some clojure and contrib myself!",
    backgroundColor: "#fff",
    color: "#000",
    position: "COO, LubJoh",
    image: ImgIcon,
    icon: X,
  },
  {
    hero: "Cole Lamar",
    position: "CEO, Lindal",
    image: ImgIcon,
    description:
      "@bountyai is really really awesome. I love the speed of implementation for new dashboards and reports. Totally awesome. Can’t wait for new features. Might as well learn some clojure and contrib myself!",
    backgroundColor: "#fff",
    color: "#000",
    icon: X,
  },
  {
    hero: "Cole Lamar",
    description:
      "@bountyai is really really awesome. I love the speed of implementation for new dashboards and reports. Totally awesome. Can’t wait for new features. Might as well learn some clojure and contrib myself!",
    backgroundColor: "#fff",
    color: "#000",
    position: "COO, LubJoh",
    image: ImgIcon,
    icon: X,
  },
  {
    hero: "Cole Lamar",
    description:
      "@bountyai is really really awesome. I love the speed of implementation for new dashboards and reports. Totally awesome. Can’t wait for new features. Might as well learn some clojure and contrib myself!",
    backgroundColor: "#fff",
    color: "#000",
    position: "COO, LubJoh",
    image: ImgIcon,
    icon: X,
  },
  {
    hero: "Cole Lamar",
    description:
      "@bountyai is really really awesome. I love the speed of implementation for new dashboards and reports. Totally awesome. Can’t wait for new features. Might as well learn some clojure and contrib myself!",
    backgroundColor: "#fff",
    color: "#000",
    position: "COO, LubJoh",
    image: ImgIcon,
    icon: X,
  },
];

const EmbeddedAnalytics = () => {
  const Card: React.FC<CardProps> = ({ title, description, isPro, circle }) => {
    return (
      <div className="bg-white p-6 rounded-lg shadow-2xl relative border-[1px] border-[#000000B2]">
        {isPro && (
          <>
            <span className="absolute top-4 left-4 bg-[#284B63] text-white font-bold px-2 py-1 rounded">
              PRO
            </span>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="max-w-auto md:max-w-[500px] lg:max-w-auto">
                <h2 className="text-2xl font-semibold mt-10 md:mt-8">
                  {title}
                </h2>
                <p className="text-gray-600 mb-4 mt-4 md:mt-0">{description}</p>
              </div>
              <div className="md:mt-8 mt-4">
                <a
                  href="/"
                  className="bg-[#284B63] rounded-[13px] p-[18px] lg:py-[25px] lg:px-[54px] text-white font-bold text-lg"
                >
                  Learn More
                </a>
              </div>
            </div>
          </>
        )}
        {circle && (
          <>
            <div className="w-10 h-10 border border-[#058FF399] rounded-full">
              {circle}
            </div>
            <h2 className="text-2xl font-semibold mt-8">{title}</h2>
            <p className="text-gray-600 mb-4">{description}</p>
            <button className="text-[#058FF3] text-[16px] flex items-center justify-center  md:text-[20px] mt-3 ">
              Learn more <Icon name="arrow" className="" />
            </button>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="m-8 md:flex gap-10">
        <div className="w-full lg:pl-20 mt-12">
          <h1 className="md:text-[34px] text-[22px] font-semibold">
            Embedded Analytics
          </h1>
          <p className="md:text-[20px] text-[18px] font-light pt-3 md:pt-0">
            Customer facing analytics in days, not sprints.
          </p>
          <ul className="mt-8 font-normal md:text-[20px] text-[16px] custom-list max-w-[500px]">
            <li>
              Power your product’s reporting by embedding charts, dashboards or
              all of Metabase.
            </li>
            <li className="pt-4">
              Launch faster than you can pick a charting library with our iframe
              or JWT-signed embeds.
            </li>
            <li className="pt-4">
              Make it your own with easy, no-code whitelabeling.
            </li>
            <li className="pt-4">
              Iterate on dashboards and visualizations with zero code, no eng
              dependencies
            </li>
          </ul>

          <button className="text-[#058FF3] md:text-[20px] text-[16px] mt-3 flex justify-center items-center gap-2">
            Learn more <Icon name="arrow" className="w-[22px] h-[22px]" />
          </button>
        </div>
        <div className=" bg-[#ECF5FB] w-full mt-5 rounded-lg h-[450px] md:h-[600px] shadow-md"></div>
      </div>

      <div className="bg-gray-50  items-center p-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full">
          <Card
            title="Self-serve Analytics"
            description="Business intelligence for everyone"
            circle
          />
          <Card
            title="Interactive Dashboards"
            description="Share your insights"
            circle
          />
          <div className="md:col-span-2">
            <Card
              title="Plans to support your team at every stage in your growth"
              description="Need tighter security, more governance, and control over who sees what? We’ve got you covered."
              isPro
            />
          </div>
        </div>
      </div>

      <div className="md:grid md:grid-cols-3 justify-center text-black gap-x-[4%]  gap-y-[4%] p-10">
        {Cards.map(
          ({ hero, description, backgroundColor, color, image, icon }) => (
            <section
              key={hero}
              className="flex flex-col px-7 rounded-md mt-6 shadow-xl  pt-8 gap-x-2 py-0 md:pb-24 lg:py-0"
              style={{ backgroundColor: backgroundColor }}
            >
              <section className="flex justify-between">
                <div className="flex gap-6">
                  <img src={image} alt="arrow" />
                  <p className="text-base pt-4 md:text-lg font-semibold">
                    {hero}
                  </p>
                </div>
                <img src={icon} alt="arrow"  className="md:w-[16px] md:h-[16px] lg:w-auto lg:h-auto"/>
              </section>

              <div
                className="text-sm lg:text-[14px] font-normal h-36 mt-7"
                style={{ color: color }}
              >
                {description}
              </div>
            </section>
          )
        )}
      </div>
      <div className="flex justify-center items-center mt-4">
        <a href="/" className="text-center text-[#058FF3] flex gap-4">
          See more love for Bounty AI <Icon name="arrow" />
        </a>
      </div>
    </>
  );
};

export default EmbeddedAnalytics;
