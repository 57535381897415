import React, { useState, useRef } from "react";
import Icon from "../../assets/icon";
import Images from "../../assets/images/demo.png";

const Demo = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handlePlay = () => {
    setIsPlaying(true);
    videoRef.current?.play();
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  return (
    <div>
      <h1 className="f font-bold md:text-[45px] text-[24px] text-center mt-14">
        Don't be a bottleneck
      </h1>
      <p className="f font-medium md:text-[24px] text-[18px] mt-2 text-center">
        Fast analytics with the friendly UX and integrated tooling{" "}
        <br className="hidden md:block" />
        to let your company explore data on their own.
      </p>

      <div className="flex justify-center items-center gap-7 mt-8 font-medium">
        <button className="bg-[#284B63] text-[18px] text-white font-medium px-4 py-2 rounded-lg">
          <a href="https://bounty-ai.onrender.com/signup" target="_blank" rel="noreferrer">
            Get Started
          </a>
        </button>
        <button  onClick={handlePlay} className="bg-white border flex gap-2 border-[#284B63] text-[18px] font-medium text-[#284B63] px-4 py-2 rounded-lg">
          <Icon name="demoicon" />
          Watch Demo
        </button>
      </div>

      <div
        className={`video-container className="md:w-1/2 flex justify-center w-[80%] mx-auto mt-10" ${
          isPlaying ? "mt-10" : ""
        }`}
      >
        {!isPlaying && (
          <img
            src={Images}
            alt="Video Thumbnail"
            className="cursor-pointer mt-10"
            onClick={handlePlay}
          />
        )}
        <video
          ref={videoRef}
          className={`${isPlaying ? "" : "hidden"}`}
          controls
          onPause={handlePause}
        >
          <source src="/demo.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Demo;
