import React from "react";
import Icon from "../../assets/icon";
import Solidarity from "../../assets/images/solidarity.png";
// import Logo from "../assets/images/Logo Desktop.svg";
const Footer = () => {
  return (
    <div className="mt-[70px] text-[#000] bg-[#E7EDF1]">
      <div className="w-[94%] mx-auto pt-7">
        <section className="grid lg:grid-cols-5 grid-cols-2 pl-6 gap-7 md:gap-0 md:pl-0 ">
          <div className="col-span-1">
            <h3 className="text-[16px] font-semibold">Product</h3>
            <ul className="mt-4 text-[14px] font-medium leading-10">
              <li>
                <a href="/#how-it-works">Watch Demo</a>
              </li>
              <li>
                <a href="/#pricing">Data Sources</a>
              </li>
              <li>
                <a href="/">Cloud</a>
              </li>
              <li>Roadmap</li>
            </ul>
          </div>

          <div className="col-span-1">
            <h3 className="text-[16px] font-semibold">Use Cases</h3>
            <ul className="mt-4 text-[14px] font-medium leading-10">
              <li>
                <a href="/#about">Self-Service Business Analytics</a>
              </li>
              <li className="relative group">
                <button className="link-style">Embedded Analytics</button>
              </li>
              <li>
                <a href="#/privacy-policy" className="font-bold">
                  Bounty AI Plans
                </a>
              </li>
              <li>
                <a href="#/terms">Starter and Open Source</a>
              </li>
              <li>
                <a href="#/terms">Pro</a>
              </li>
              <li>
                <a href="#/terms">Enterprise</a>
              </li>
            </ul>
          </div>

          <div className="col-span-1">
            <h3 className="text-[16px] font-semibold">Features</h3>
            <ul className="mt-4 text-[14px] font-medium leading-10">
              <li>Query Builder</li>
              <li>Drill Through</li>
              <li>Collections</li>
              <li>Analytics dashboards</li>
              <li>SQL editor</li>
              <li>Sandboxing</li>
              <li>Models</li>
              <li>Permissions</li>
              <li>CSV upload</li>
            </ul>
          </div>

          <div className="col-span-1">
            <h3 className="text-[16px] font-semibold">Company</h3>
            <ul className="mt-4 text-[14px] font-medium leading-10">
              <li>
                <a href="/#how-it-works"> Jobs</a>
              </li>
              <li>
                <a href="/#pricing">Privacy Policy</a>
              </li>
              <li>
                <a href="/">Terms of service</a>
              </li>
              <li>Contact Us</li>
              <li className="font-bold">Support</li>
              <li>Documentation</li>
              <li>Help</li>
              <li>Professional Services</li>
              <li>Status</li>
            </ul>
          </div>

          <div className="col-span-1">
            <h3 className="text-[16px] font-semibold">Resources</h3>
            <ul className="mt-4 text-[14px] font-medium leading-10">
              <li>Case Studies</li>
              <li>Blog</li>
              <li>Community Stories</li>
              <li>Data and Business Intelligence Glossary</li>
              <li>Partners</li>
              <li>The Startup’ Guide to Financial Modeling</li>
              <li>More Resources</li>
            </ul>
          </div>
        </section>

        <hr className="mt-12 h-[2px] bg bg-[#D0DBE3]" />
        <div className="md:flex md:justify-between md:mx-0 mx-4  mt-8 h-[50px]">
          <Icon name="footerlogo" />
          <section className="flex justify-between md:gap-5 mt-4 md:mt-0">
            <a
              href="/#https://x.com/vendstash?s=20"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="facebook" className="bg-[#D0DBE3] rounded-md p-1" />
            </a>

            <a
              href="/#https://web.facebook.com/people/Vendstash/61554099410029/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="x" className="bg-[#D0DBE3] rounded-md p-1" />
            </a>

            <a
              href="/#https://www.instagram.com/vendstash_/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="linkedin" className="bg-[#D0DBE3] rounded-md p-1" />
            </a>

            <a
              href="/#https://www.linkedin.com/company/vendstash/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="youtube" className="bg-[#D0DBE3] rounded-md p-1" />
            </a>
            <a
              href="/#https://www.linkedin.com/company/vendstash/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="ig" className="bg-[#D0DBE3] rounded-md p-1" />
            </a>
          </section>
        </div>

        <div className="md:mt-5 text-center mt-24 md:w-[30%]  h-96">
          <h1 className="f font-semibold">
            An initiative of Diaspora Solidarity Group
            <span className="block text-[14px] font-normal">
              <i>
                CUI ~ Asociatia Diaspora Solidarity Group 46365471, Municipiul
                Bucuresti, Bulevardul 1 Decembrie 1918 nr. 2, bl. MY9, sc 1.
                sector 3.
              </i>
            </span>
            <div className="flex justify-center md:flex-col">
            <img src={Solidarity} className="w-[220px] rounded-lg h-[220px] mt-3" alt="solidarity" />
            </div>
          </h1>

         
        </div>
      </div>
    </div>
  );
};

export default Footer;
